
@include keyframes(bg-roll-left) {
  0% { transform: rotate(0deg) scale(1); }
  10% { transform: rotate(-36deg) scale(2); }
  25% { transform: rotate(-90deg) scale(2.5); }
  50% { transform: rotate(-180deg) scale(2.5); }
  75% { transform: rotate(-270deg) scale(2.5); }
  90% { transform: rotate(-324deg) scale(2); }
  100% { transform: rotate(-360deg) scale(1); }
}

@include keyframes(bg-roll-right) {
  0% { transform: rotate(0deg) scale(1); }
  10% { transform: rotate(36deg) scale(2); }
  25% { transform: rotate(90deg) scale(2.5); }
  50% { transform: rotate(180deg) scale(2.5); }
  75% { transform: rotate(270deg) scale(2.5); }
  90% { transform: rotate(324deg) scale(2); }
  100% { transform: rotate(360deg) scale(1); }
}

.section-background.roll-left {
  @include prefix((animation-name: bg-roll-left), webkit);
  @include prefix((animation-duration: 1s), webkit);
  @include prefix((animation-timing-function: linear), webkit);
}

.section-background.roll-right {
  @include prefix((animation-name: bg-roll-right), webkit);
  @include prefix((animation-duration: 1s), webkit);
  @include prefix((animation-timing-function: linear), webkit);
}