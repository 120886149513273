
@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

/* shamelessly borrowed from https://www.sitepoint.com/css3-starwars-scrolling-text/ */


// I could not figure out a good way to calculate the position of the transformed element, so a magic number is a temporary solution
$STORY_HEIGHT_SM: 3300px;
$STORY_HEIGHT_MD: 3300px;
$STORY_HEIGHT_LG: 3300px;
$STORY_HEIGHT_XL: 3400px;
$STORY_HEIGHT_XXL: 3700px;

.story-container {
  position: absolute;
  width: 18em;
  // height: 425vh;
  bottom: 0;
  left: 50%;
  margin-left: -9em;
  font-size: 350%;
  text-align: justify;
  overflow: hidden;

  height: $STORY_HEIGHT_SM;
  @include respond($BREAKPOINT_MOBILE) { height: $STORY_HEIGHT_SM; }
  @include respond($BREAKPOINT_TABLET) { height: $STORY_HEIGHT_MD; }
  @include respond($BREAKPOINT_LAPTOP) { height: $STORY_HEIGHT_LG; }
  @include respond($BREAKPOINT_DESKTOP) { height: $STORY_HEIGHT_XL; }
  @include respond($BREAKPOINT_DESKTOP_XL) { height: $STORY_HEIGHT_XXL; }

  @include prefix((transform-origin: 50% 100%), webkit moz ms o);
  @include prefix((transform: perspective(300px) rotateX(25deg)), webkit moz ms o);

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 60%;
    pointer-events: none;
    $linear-gradient: to bottom, rgba(0,0,0,1) 0%, transparent 100%;
    background-image: -webkit-linear-gradient($linear-gradient);
    background-image: -moz-linear-gradient($linear-gradient);
    background-image: -ms-linear-gradient($linear-gradient);
    background-image: -o-linear-gradient($linear-gradient);
    background-image: linear-gradient($linear-gradient);
  }
}

$story-color-fg: #fffd91;

.story-text {
  position: absolute;
  top: 100%;

  color: $story-color-fg;
  text-shadow: 0 0 15px lighten($story-color-fg, 10%);

  h2 {
    font-size: 3em;
    font-weight: 900;
    margin-bottom: 0.2em;
  }

  h3 {
    font-size: 1.60em;
    font-weight: 100;
    margin-bottom: 2em;
  }

  p,
  ul {
    margin-bottom: 1em;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  li {
    list-style: none;
  }
}

