/* This stylesheet controls how things are first rendered, e.g. first paint. */

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #222;
  color: #fefefe;
}
