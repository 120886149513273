
@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

.video-warp-speed {
  position: fixed;
  top: 50%; left: 50%;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  min-width: 100vw;
  min-height: 100vh;

  width: auto;
  height: 100%;

  @include transform(translate(-50%, -50%));

  @media (min-aspect-ratio: 16/9) {
    width: 100%; 
    height: auto
  }

  &.playing {
    z-index: 4321;
  }
}

.thanks {
  position: relative;
  z-index: 4321;
}