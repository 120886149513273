
@import './vars.scss';
@import './mixins.scss';
@import './colors.scss';
@import './typography.scss';
@import './globals.scss';
@import './barrel-roll.scss';

// z-indexes
#section-main {  z-index: 7; }
#section-story { z-index: 5; }
#section-skillset-inventory { z-index: 8; }
#section-portfolio { z-index: 4; }
#section-warp-speed { z-index: 1; }


// hack - guarantee last section can get scrolled to
#section-warp-speed { min-height: 101vh; }