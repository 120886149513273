
@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

$bounce-distance: 15px;
$bounce-timing: 2.5s;

@include keyframes('bouncing-scroll-guide') {
  0% { transform: translateY(0); }
  65% { transform: translateY(0); }
  72% { transform: translateY($bounce-distance); }
  79% { transform: translateY(0); }
  86% { transform: translateY($bounce-distance); }
  100% { transform: translateY(0); }
}

.scroll-guide {
  position: fixed;
  z-index: 77;
  cursor: pointer;

  @include ease-in-out(0.3s);

  svg {
    display: block;
    text-align: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    stroke: inherit;
  }

  .scroll-guide-text {
    position: absolute;
    z-index: 77;
    width: 100%;
    top: 0;
    left: 0;
    margin-top: 15px;
    font-weight: 400;
    // line-height: 30px;
    text-align: center;
    color: inherit;

    text {
      font-size: 8px;
      font-weight: 100;
      letter-spacing: 0.1em;
      opacity: 0.8;
    }
  }
}

.scroll-guide-arrow {
  @include ease-in-out(0.2s);

  .current-section-main .scroll-guide.theme-light:not(:hover) & {
    @include animate(bouncing-scroll-guide, $bounce-timing, ease-in-out, infinite);
  }
}

.scroll-guide:hover {
  .scroll-guide-arrow {
    transform: translateY($bounce-distance)!important;
  }
}

//
// P O S I T I O N I N G
//
.scroll-guide {
  bottom: 20px;
  left: 50%;
  // offset for half item width to center on screen.
  margin-left: -30px;

  @include transform(scale(1));
}

.scroll-guide {
  [class*="current-section-story"] & {
    bottom: 40%;
    left: 30px;
    margin-left: 0;

    @include transform(scale(0.7));
  }
}

.scroll-guide {
  [class*="current-section-skillset-inventory"] &,
  [class*="current-section-portfolio"] &,
  [class*="current-section-warp-speed"] & {
    bottom: 40%;
    left: -60px;
    margin-left: 0;

    @include transform(scale(0.9));
  }
}

//
// T H E M I N G
//

.scroll-guide,
.scroll-guide.theme-light {
  opacity: 0.7;
  color: rgb(0,0,0);
  stroke: rgb(0,0,0);
}

.scroll-guide.theme-dark,
.scroll-guide.theme-transparent,
.scroll-guide.theme-red,
.scroll-guide.theme-blue {
  opacity: 0.8;
  color: rgb(255,255,255);
  stroke: rgb(255,255,255);
}

