
$colorBgMain: #282c34;
$colorFgMain: #fefefe;

$colorBlueLight: #17a2b8;
$colorBlueDark: #0c5460;
$colorGray: #9f9e98;
$colorSilver: #c1c1ba;
$colorWhiteSnow: #fefefe;

$BREAKPOINT_MOBILE: 576px;
$BREAKPOINT_TABLET: 768px;
$BREAKPOINT_LAPTOP: 992px;
$BREAKPOINT_DESKTOP: 1200px;
$BREAKPOINT_DESKTOP_XL: 1600px;

$dashboardTimeShowDuration: 1000ms;
$dashboardTimeTransition: 500ms;

//
// D A S H B O A R D   P O S I T I O N I N G
//
$dashOffsetMob: 60px;
$dashOffsetDesk: 140px;

//
// T H E M I N G
//

$themeLightColorBg: rgba(0,0,0,0.7);
$themeLightColorFg: #fefefe;

$themeTransColorBg: rgba(0,0,0,0.3);
$themeTransColorBgButton: rgba(255, 255, 255, 0.5);
$themeTransColorFg: #fefefe;

$themeDarkColorBg: rgba(255, 255, 255, 0.8);
$themeDarkColorFg: #333;

$themeSilverColorBg: transparentize($colorSilver, 0.2);
$themeSilverColorFg: #fff;

$themeBlueColorBg: rgba(33, 52, 65, 0.57);
$themeBlueColorFg: #c8e9f7;
$themeBlueColorFgShadow: rgba(202, 235, 255, 0.81);

$themeRedColorBg: rgba(177, 60, 52, 0.57);
$themeRedColorFg: #f7c8c8;
$themeRedColorFgShadow: rgba(255, 202, 202, 0.81);