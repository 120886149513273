
@mixin expand() {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fullscreen {
  position: fixed;
  
  @include expand();
}

.fill-absolute {
  position: absolute;
  
  @include expand();
}

.ease-in-out {
  @include ease-in-out(0.5s);
}

.over-fullscreen {
  position: relative;
  z-index: 7;
}

.no-select {
  user-select: none;
}

.background-cover {
  background-size: cover;
  background-position: center;
}

.cursor-pointer {
  cursor: pointer;
}

.letter-spacing-0 {
  letter-spacing: 0;
}
.letter-spacing-0-5 {
  letter-spacing: 0.05em;
}
.letter-spacing-1 {
  letter-spacing: 0.1em;
}
.letter-spacing-1-5 {
  letter-spacing: 0.15em;
}
.letter-spacing-2 {
  letter-spacing: 0.2em;
}
.letter-spacing-2-5 {
  letter-spacing: 0.25em;
}
.letter-spacing-3 {
  letter-spacing: 0.3em;
}
.letter-spacing-3-5 {
  letter-spacing: 0.35em;
}
