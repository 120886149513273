
@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

// since the viewboxes are square, both
// height and width are the same val.
$heightDeco: 20px;
$offsetDeco: 250px;
$offsetDecoMob: 75px;

.deco-top,
.deco-bottom,
.deco-left,
.deco-right {
  position: absolute;
  z-index: 77;

  svg,
  .fill,
  .l-1-fill,
  .l-2-fill {
    position: absolute;
  }

  svg,
  polygon {
    width: $heightDeco;
    height: $heightDeco;
    background-color: transparent!important;
  }

  // the .bg class gets set on the svg element
  polygon {
    fill: inherit;
  }
}

.deco-top,
.deco-bottom {
  left: 0;
  right: 0;

  svg,
  .fill,
  .l-1-fill,
  .l-2-fill {
    height: $heightDeco;
  }
}

//
// D E C O   T O P
//
.deco-top {
  bottom: 100%;
  height: $heightDeco * 2;

  .l-1-tri-left {
    left: 0;
    bottom: 0;
  }

  .l-1-tri-right {
    right: 0;
    bottom: 0;
  }

  .l-2-tri-left {
    left: $offsetDecoMob - $heightDeco;
    bottom: $heightDeco;

    @include respond($BREAKPOINT_TABLET) {
      left: $offsetDeco - $heightDeco;
    }
  }

  .l-2-tri-right {
    right: $offsetDecoMob - $heightDeco;
    bottom: $heightDeco;

    @include respond($BREAKPOINT_TABLET) {
      right: $offsetDeco - $heightDeco;
    }
  }

  .l-1-fill {
    // left: $heightDeco;
    // right: $heightDeco;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .l-2-fill {
    left: $offsetDecoMob;
    right: $offsetDecoMob;
    bottom: $heightDeco;

    @include respond($BREAKPOINT_TABLET) {
      left: $offsetDeco;
      right: $offsetDeco;
    }
  }
}

//
// D E C O   B O T T O M
//
.deco-bottom {
  top: 100%;
  height: $heightDeco;

  .tri-left {
    left: $offsetDecoMob - $heightDeco;
    bottom: 0;

    @include respond($BREAKPOINT_TABLET) {
      left: $offsetDeco - $heightDeco;
    }
  }

  .tri-right {
    right: $offsetDecoMob - $heightDeco;
    bottom: 0;

    @include respond($BREAKPOINT_TABLET) {
      right: $offsetDeco - $heightDeco;
    }
  }

  .fill {
    left: $offsetDecoMob;
    right: $offsetDecoMob;
    bottom: 0;

    @include respond($BREAKPOINT_TABLET) {
      left: $offsetDeco;
      right: $offsetDeco;
    }
  }
}

$offsetDecoSide: 100px;
.deco-left,
.deco-right {
  top: 0;
  bottom: 0;

  svg,
  .fill,
  .l-1-fill,
  .l-2-fill {
    width: $heightDeco;
  }
}

//
// D E C O   L E F T
//
.deco-left {
  right: 100%;
  width: $heightDeco * 2;

  .l-1-tri-top {
    right: 0;
    top: 0;
  }

  .l-1-tri-bottom {
    right: 0;
    bottom: 0;
  }

  .l-2-tri-top {
    right: $heightDeco;
    top: $offsetDecoSide;
  }

  .l-2-tri-bottom {
    right: $heightDeco;
    bottom: $offsetDecoSide;
  }

  .l-1-fill {
    top: $heightDeco;
    bottom: $heightDeco;
    right: 0;
  }

  .l-2-fill {
    top: $heightDeco + $offsetDecoSide;
    bottom: $heightDeco + $offsetDecoSide;
    right: $heightDeco;
  }
}

//
// D E C O   R I G H T
//
.deco-right {
  left: 100%;
  width: $heightDeco * 2;

  .l-1-tri-top {
    left: 0;
    top: 0;
  }

  .l-1-tri-bottom {
    left: 0;
    bottom: 0;
  }

  .l-2-tri-top {
    left: $heightDeco;
    top: $offsetDecoSide;
  }

  .l-2-tri-bottom {
    left: $heightDeco;
    bottom: $offsetDecoSide;
  }

  .l-1-fill {
    top: $heightDeco;
    bottom: $heightDeco;
    left: 0;
  }

  .l-2-fill {
    top: $heightDeco + $offsetDecoSide;
    bottom: $heightDeco + $offsetDecoSide;
    left: $heightDeco;
  }
}