
@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';
@import '../../scss/colors.scss';

.section-skillset-inventory {
  position: relative;
  // required to display the deco-bottom over the next section
  z-index: 8;

  .section-background {
    background-color: $colorGray!important;
  }

  .bg {
    background-color: $colorGray;
    fill: $colorGray;
  }
}
