
@mixin font-titillium {
  font-family: 'Titillium Web', sans-serif;
}

@mixin font-roboto {
  font-family: 'Roboto Condensed', sans-serif;
}

@mixin font-sans {
  font-family: 'Open Sans', sans-serif;
}

@mixin font-sans-condensed {
  font-family: 'Open Sans Condensed', sans-serif;
}

@mixin font-raleway {
  font-family: 'Raleway', sans-serif;
}

@mixin font-monospace () {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.font-titillium {
  @include font-titillium();
}

.font-roboto {
  @include font-roboto();
}

.font-sans {
  @include font-sans();
}

.font-sans-condensed {
  @include font-sans-condensed();
}

.font-raleway {
  @include font-raleway();
}

.font-monospace {
  @include font-monospace();
}

@mixin h-font-size($fontSizeXs: false, $fontSizeMd: false, $fontSizeLg: false, $fontSizeXl: false) {
  @include font-size($fontSizeXs);

  @if $fontSizeMd {
    @include respond($BREAKPOINT_TABLET) {
      @include font-size($fontSizeMd);
    }
  }

  @if $fontSizeLg {
    @include respond($BREAKPOINT_LAPTOP) {
      @include font-size($fontSizeLg);
    }
  }

  @if $fontSizeXl {
    @include respond($BREAKPOINT_DESKTOP) {
      @include font-size($fontSizeXl);
    }
  }
}

@mixin h-line-height($lineHeightXs: false, $lineHeightMd: false, $lineHeightLg: false, $lineHeightXl: false) {
  line-height: $lineHeightXs;

  @if $lineHeightMd {
    @include respond($BREAKPOINT_TABLET) {
      line-height: $lineHeightMd;
    }
  }

  @if $lineHeightLg {
    @include respond($BREAKPOINT_LAPTOP) {
      line-height: $lineHeightLg;
    }
  }

  @if $lineHeightXl {
    @include respond($BREAKPOINT_DESKTOP) {
      line-height: $lineHeightXl;
    }
  }
}

// font sizing, line height
h1 { @include h-font-size(60px, 70px, 85px); @include h-line-height(1.2em, 1.3em, 1.5em); }
h2 { @include h-font-size(35px, 45px, 50px); @include h-line-height(1.2em, 1.2em, 1.3em); }
h3 { @include h-font-size(30px, 30px, 35px); @include h-line-height(1.4em, 1.2em, 1.3em); }
h4 { @include h-font-size(21px, 32px, 35px); @include h-line-height(1.2em, 1.2em, 1.2em); }
h5 { @include h-font-size(26px, 30px, 32px); @include h-line-height(1.2em, 1.2em, 1.2em); }
h6 { @include h-font-size(21px, 25px, 25px); @include h-line-height(1.2em, 1.2em, 1.2em); }

// font family
h1 { @extend .font-titillium;  }
h2 { @extend .font-sans; }
h3 { @extend .font-roboto; }
h4 { @extend .font-raleway; }
h5 { @extend .font-sans-condensed; }
h6 { @extend .font-sans-condensed; }

// font-weight
// h1 { font-weight: 100; }
h2 { font-weight: 100; }
// h3 { font-weight: 100; }
// h4 { font-weight: 100; }
// h5 { font-weight: 100; }
// h6 { font-weight: 100; }

// font extras
// h1 {}
// h2 {}
h3 { text-transform: uppercase; }
// h4 {}
// h5 {}
// h6 {}


code {
  @extend .font-monospace;
  background-color: #3e3626;
  color: #ffc107; 
  padding-left: 1em; 
  padding-right: 1em;
  border-radius: 3px;
}

a {
  color: $colorBlueLight;

  @include ease-in-out(0.2s);
  @include hover() {
    color: darken($colorBlueLight, 10%);
    text-decoration: none;
  }
}
