
@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';


$bgContact: lighten($colorSilver, 15%);

.contact-panel {
  position: fixed;
  z-index: 77777;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 120%;
  left: calc(100% + 40px);

  background-color: $bgContact;
  
  @include ease-in-out-bezier(1s);

  &.showing {
    left: 0;
  }

  .bg {
    background-color: $bgContact;
    fill: $bgContact;
  }

  .close {
    position: absolute;
    top: 55px;
    right: 25px;
    cursor: pointer;

    @include respond($BREAKPOINT_TABLET) {
      top: 100px;
      right: 100px;
    }
  }
}
